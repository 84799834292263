// import './component.sass'
import { ready }from 'src/utils'

ready(() => {
  const bestBrands = document.querySelector('[data-ref="best-brands"]')
  if (!bestBrands || bestBrands.length === 0) return

  window.addEventListener('resize', () => slicer(bestBrands), true)

  slicer(bestBrands)
})

const slicer = bestBrands => {
  const width = window.innerWidth
  const brands = Array.from(bestBrands.children)
  showAll(brands)
  const last = brands.length - 1

  switch (true) {
    case width <= 1650:
      hide(brands.slice(5, last))
      break
    case width <= 1920:
      hide(brands.slice(8, last))
      break
  }
}

const showAll = elements => {
  elements.forEach(e => {
    e.style.display = 'flex'
  })
}

const hide = elements => {
  elements.forEach(e => {
    e.style.display = 'none'
  })
}
