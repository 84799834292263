import { ready }from 'src/utils'
import './component.sass'

ready(() => {
  const form = document.querySelector('#payment-form')
  if (!form) return

  const { confirmation_token, return_url } = form.dataset
  let checkout

  if (typeof checkout === 'undefined') {
    checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token,
      return_url,
      customization: {
        colors: {
          controlPrimary: '#009fe1',
          controlPrimaryContent: '#fff',
          background: '#fff',
          text: '#414141',
          border: '#fff'
        }
      },
      error_callback: function (error) {
        console.error(error)
      }
    })
  }

  if (typeof checkout !== 'undefined') checkout.render('payment-form')
})
