// import './component.sass'
import debounce from 'lodash-es/debounce'
import { ready } from 'src/utils'

// Source: http://stackoverflow.com/questions/30734552/change-url-while-scrolling
// stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport

const isElementInViewport = el => {
  const rect = el.getBoundingClientRect()

  // console.log('====================')
  // console.log('IN VIEWPORT RECT: ', el.getAttribute('id'), rect.top, rect.bottom)
  // console.log('IN VIEWPORT INNER HEIGHT: ', (window.innerHeight || document.documentElement.clientHeight))
  // console.log('IN VIEWPORT TOP >= 130: ', (rect.top >= 130))
  // console.log('IN VIEWPORT BOTTOM <= INNER HEIGHT: ',
  //   (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)))

  return (
    rect.top >= 80 &&
    rect.top < ((window.innerHeight || document.documentElement.clientHeight) / 2) &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  )
}

const addCoverToRest = (el, collection) => {
  collection.forEach(step => step.classList.add('covered'))
  // console.log('ADD COVER TO REST: el → ', el)
  // console.log('====================')

  el.classList.remove('covered')
}

const scrollChanging = debounce((steps, currentHash) => {
  steps.forEach(el => {
    currentHash = el.getAttribute('id')
    if (!currentHash) return

    const result = isElementInViewport(el)
    // console.log('isElementInViewport', result, currentHash)

    if (!result) {
      // console.log('====================')
      return
    }

    // window.location.hash = currentHash
    history.pushState({}, '', `#${currentHash}`)

    // console.log('SCROLL HASH', currentHash)

    const currentStep = document.getElementById(currentHash.replace('#', ''))
    addCoverToRest(currentStep, steps)
  })
}, 300)

ready(() => {
  let steps = Array.prototype.slice.call(document.querySelectorAll('[data-ref="order_step"]')).reverse()

  if (steps.length === 0) return
  const currentHash = window.location.hash
  const checkBlock = document.getElementById('check')
  // console.log('CURRENT HASH', currentHash)

  if (currentHash) {
    const currentStep = document.getElementById(currentHash.replace('#', ''))
    addCoverToRest(currentStep, steps)
    if (currentHash === '#new') addCoverToRest(checkBlock, steps)
  } else {
    addCoverToRest(checkBlock, steps)
  }

  let oldValue = 0
  let newValue = 0

  window.addEventListener('scroll', _e => {
    newValue = window.pageYOffset

    if (oldValue > newValue) {
      // console.log('UP')
      steps = steps.reverse()
    } else if (oldValue < newValue) {
      // console.log('DOWN')
    }
    oldValue = newValue

    scrollChanging(steps, currentHash)
  })
})
